export const defaultLang = 'ja'
const supportedLang = ['en', 'ja']

export function language() {
  if (typeof window !== 'undefined') {
    // Access localStorage here
    const lang = localStorage.getItem('lang')?.toLowerCase()

    return supportedLang.includes(lang) ? lang : defaultLang
  }

  return defaultLang
}
