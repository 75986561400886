// src/i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslation from '@/locales/en.json'
import jaTranslation from '@/locales/ja.json'
import { language } from '@/helpers/Language'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation
    },
    ja: {
      translation: jaTranslation
    }
  },
  lng: language(),
  fallbackLng: 'ja',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
