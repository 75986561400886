exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-amazon-data-handling-policy-tsx": () => import("./../../../src/pages/en/amazon-data-handling-policy.tsx" /* webpackChunkName: "component---src-pages-en-amazon-data-handling-policy-tsx" */),
  "component---src-pages-en-privacy-policy-tsx": () => import("./../../../src/pages/en/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-policy-tsx" */),
  "component---src-pages-en-terms-of-service-tsx": () => import("./../../../src/pages/en/terms_of_service.tsx" /* webpackChunkName: "component---src-pages-en-terms-of-service-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-all-news-pagination-tsx": () => import("./../../../src/pages/news/AllNewsPagination.tsx" /* webpackChunkName: "component---src-pages-news-all-news-pagination-tsx" */),
  "component---src-pages-news-announcement-announcement-pagination-tsx": () => import("./../../../src/pages/news/announcement/AnnouncementPagination.tsx" /* webpackChunkName: "component---src-pages-news-announcement-announcement-pagination-tsx" */),
  "component---src-pages-news-announcement-tsx": () => import("./../../../src/pages/news/Announcement.tsx" /* webpackChunkName: "component---src-pages-news-announcement-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/NewsIndex.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-media-coverage-media-coverage-pagination-tsx": () => import("./../../../src/pages/news/media-coverage/MediaCoveragePagination.tsx" /* webpackChunkName: "component---src-pages-news-media-coverage-media-coverage-pagination-tsx" */),
  "component---src-pages-news-media-coverage-tsx": () => import("./../../../src/pages/news/MediaCoverage.tsx" /* webpackChunkName: "component---src-pages-news-media-coverage-tsx" */),
  "component---src-pages-news-press-release-press-release-pagination-tsx": () => import("./../../../src/pages/news/press-release/PressReleasePagination.tsx" /* webpackChunkName: "component---src-pages-news-press-release-press-release-pagination-tsx" */),
  "component---src-pages-news-press-release-tsx": () => import("./../../../src/pages/news/PressRelease.tsx" /* webpackChunkName: "component---src-pages-news-press-release-tsx" */),
  "component---src-pages-news-prismic-new-data-slug-tsx": () => import("./../../../src/pages/news/{PrismicNew.data__slug}.tsx" /* webpackChunkName: "component---src-pages-news-prismic-new-data-slug-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

